var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("PdfPreviewTemplate", {
    attrs: {
      isAvailable: _vm.isAvailable,
      pdfBlob: _vm.pdfBlob,
      errorMessage: _vm.errorMessage
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }